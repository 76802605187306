import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';

const about = () => {
  return (
    <Layout>
      <Container className="section" id="about">
        <div className="section-center">
          {/* About Image */}
          <div className="img-container">
            <StaticImage
              src="../assets/images/about.JPG"
              alt="about me"
              class="about-img"
              placeholder="dominantColor"
            />
          </div>
          {/* About Content */}
          <div className="about-content">
            <div className="about-title">
              <h2 className="title">about</h2>
            </div>
            <p className="about-text big">
              I'm a long time So California resident that loves all things real
              estate. I love buying, selling and remodeling. I enjoy helping
              clients realize their real estate dreams. Southern California with
              its miles of beaches and mountains, I take advantage of the good
              weather every chance I get and walk all the trails in the area.
            </p>
            <p className="about-text small">
              And, I help people buy, sell, invest and renovate property all
              over Orange County and beyond. I look forward to meeting you!
            </p>
            {/* About Button */}
            <div className="btn-container">
              <Link to="/contact" className="btn about-btn">
                Contact Me
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  width: 100%;
  background: #fcfcfc;

  .section-center {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
  }
  .about-title {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 2rem;
    .title {
      font-weight: 200;
      text-transform: capitalize;
    }
  }
  .about-content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .img-container {
    width: 100%;
    margin-bottom: 4rem;
  }
  .about-img {
    width: 100%;
  }
  .about-text {
    width: 85%;
  }
  .big {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .small {
    margin-bottom: 3rem;
  }
  .about-btn {
    color: #ffffff;
    background-color: #00c6b5;
    padding: 0.875rem 1.25rem;
    &:hover {
      background-color: #33c1b6;
    }
  }

  @media (min-width: 800px) {
    .section-center {
      flex-direction: row;
    }
    .img-container {
      padding-right: 1rem;
      margin-bottom: 0;
    }
    .about-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 3rem;
    }
    .about-title {
      margin-bottom: 1rem;
    }
    .about-text {
      width: 100%;
    }
    .small {
      margin-bottom: 3rem;
    }
  }
`;

export default about;
